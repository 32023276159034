@use "../../base";

#fileInfo {
  @include base.OmnesMedium;
  border-bottom: 1px solid base.$tertiary4;
  border-top: 1px solid base.$tertiary4;
  display: flex;
  flex-direction: row;
  padding-top: 13px;
  padding-bottom: 13px;
  justify-content: space-between;
  margin-bottom: 36px;
  div {
  }
}
