@use "../../base";

.checkbox {
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      & ~ .checkmark {
        background-color: base.$primary;
        &:after {
          display: block;
        }
      }
    }
  }
  .checkmark {
    &:after {
      left: 8px;
      top: 3px;
      width: 8px;
      height: 15px;
      border: 1px solid base.$color-white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.checkmark {
  height: 25px;
  width: 25px;
  background: base.$color-white;
  border: 1px solid base.$tertiary6;
  border-radius: 3px;

  &.checkmark--checked {
    border: 1px solid base.$primary;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}
