@use "../../base";

.shared-files {
  width: 100%;
  padding-top: 21px;
  padding-left: 35px;
  padding-right: 28px;

  .shared-files-header {
    padding-bottom: 45px;
  }

  .shared-files-shared-by {
    display: flex;

    .shared-files-shared-by-name {
      background: #ecf0f4;
      border-radius: 8px;
      padding: 8px;
      display: flex;
      margin-right: 8px;
    }
  }
}
