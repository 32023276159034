@use "../../base";

.my-files {
  width: 100%;
  padding-top: 21px;
  padding-left: 35px;
  padding-right: 28px;

  .my-files-header {
    padding-bottom: 45px;
  }

  .my-files-shared-with {
    display: flex;

    .my-files-shared-with-name {
      background: base.$secondary2;
      border-radius: 16px;
      padding: 8px;
      display: flex;
      margin-right: 8px;
      color: base.$tertiary6;
    }
  }

  .my-files-share-file {
    button {
      display: flex;
      border: none;
      padding: 8px;

      span {
        font-size: 14px;
        line-height: 22px;
        margin-left: 5px;
      }
    }
  }
}
