@use "base";

.routes-container {
  display: flex;

  .routes-container-pages {
    background: base.$tertiary5-alpha25;
    width: 100%;
    height: calc(100% - 110px);
  }
}
