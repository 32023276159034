@use "../../base";

.table {
  background-color: base.$secondary8;
  width: 100%;
  border-radius: 16px;
  border-collapse: collapse;

  tbody {
    border-top: none !important;
  }

  .table-header {
    tr {
      th {
        background-color: base.$secondary1-alpha40;
        color: base.$tertiary6;
        font-size: 16px;
        line-height: 22px;
        @include base.OmnesSemiBold;

        &:first-child {
          border-top-left-radius: 16px;
        }

        &:last-child {
          border-top-right-radius: 16px;
        }
      }
    }
  }

  .table-row {
    border-color: transparent;
    border-style: none;
    border-width: 0;

    .table-cell {
      padding: 10px 15px !important;

      .table-cell-checkbox {
        display: grid;
      }
    }

    .cell-details {
      width: 25px;
      img {
        padding-top: 6px;
      }

      img:hover {
        cursor: pointer;
        opacity: 80%;
      }
    }

    .table-cell-details {
      width: 25px;
    }

    .table-data {
      .table-data-text {
        font-size: 16px;
        line-height: 22px;
        @include base.OmnesMedium;
        color: base.$tertiary6;
      }
    }
    .table-data.cell-0 {
      .table-data-text {
        font-size: 16px;
        line-height: 22px;
        @include base.OmnesSemiBold;
        color: base.$tertiary6;
      }
    }
  }
}
