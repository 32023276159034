@use "../../base";

.file-upload {
  .file-upload-heading {
    @include base.OmnesSemiBold;
    font-size: 20px;
    line-height: 22px;
    color: base.$tertiary6;
  }
  .file-upload-warning {
    @include base.OmnesRegular;
    font-size: 16px;
    line-height: 147.12%;
    color: base.$primary;
    padding-top: 10px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-weight: bold;
    border-bottom: 1px solid base.$tertiary4;
  }

  .file-upload-details {
    @include base.OmnesRegular;
    font-size: 16px;
    line-height: 147.12%;
    color: base.$tertiary6;
    padding-top: 10px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    .bold {
      font-weight: bold;
    }
    a {
      color: base.$tertiary6;
    }
  }

  .file-upload-drag-drop {
    width: 100%;
    height: 141px;
    background: base.$secondary2-alpha15;
    border: 1px solid base.$secondary1;
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    cursor: pointer;
    padding: 1rem 0;

    &.file-upload-drag-drop--dragging {
      border: 1px solid base.$primary;
    }

    .file-upload-drag-drop-icon {
      background: base.$secondary2;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-top: 23px;
    }

    .file-upload-drag-drop-description {
      margin-top: 14px;
      font-size: 16px;
      line-height: 24px;
      @include base.OmnesSemiBold;
      color: base.$tertiary6;
    }

    .file-upload-drag-drop-size {
      font-size: 16px;
      line-height: 24px;
      @include base.OmnesLight;
      color: base.$tertiary6;
    }
  }

  .file-upload-attached-files {
    margin-top: 23px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1.33718px solid base.$tertiary4;

    .file-upload-attached-files-list {
      margin-top: 17px;
      width: 100%;
      height: 68px;
      background: base.$secondary2-alpha50;
      border: 0.5px solid base.$primary;
      box-sizing: border-box;
      border-radius: 16px;
      padding: 7px 12px;

      .file-upload-attached-files-list-empty {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include base.OmnesLight;
        color: base.$primary;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
      }

      .file-upload-attached-files-list-details {
        display: flex;
        align-items: center;

        .file-upload-attached-files-list-details-icon {
          width: 21px;
          height: 21px;
          background: base.$secondary8;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .file-upload-attached-files-list-details-info {
          @include base.OmnesMedium;
          padding-left: 7px;
          color: base.$tertiary6;
          font-size: 16px;
          line-height: 22px;
          width: 85%;
          display: flex;

          div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          div {
            &:last-child {
              padding-left: 10px;
              width: 30%;
              text-align: end;
            }

            &:first-child {
              width: 70%;
            }
          }
        }

        .file-upload-attached-files-list-details-delete {
          margin-left: auto;
          img {
            width: 20px;
          }
        }
      }

      .file-upload-attached-files-list-progress {
        display: flex;
        margin-top: 13px;
        align-items: center;

        .file-upload-attached-files-list-progress-line {
          height: 5px;
          background: base.$primary;
          border-radius: 8px;
          width: 70%;
          margin-left: 26px;
        }

        .file-upload-attached-files-list-progress-percentage {
          @include base.OmnesMedium;
          font-size: 14px;
          line-height: 22px;
          color: base.$tertiary6;
          padding-left: 10px;
        }

        .file-upload-attached-files-list-progress-icon {
          margin-left: auto;
          display: flex;
          align-items: center;

          img {
            width: 20px;
          }
        }
      }
    }

    .file-upload-attached-files-description {
      margin-top: 13px;
      @include base.OmnesRegular;
      color: base.$tertiary6;
      font-size: 15px;
      line-height: 147.12%;
    }
  }

  .file-upload-search {
    position: relative;
    width: 100%;
    background: base.$tertiary5;
    border: 1px solid base.$tertiary4;
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 25px;
    background: base.$tertiary5-alpha25;

    div {
      width: 100%;
      background: none;
    }

    img {
      margin-top: -5px;
    }

    input {
      border: none;
      @include base.OmnesRegular;
      font-size: 16px;
      height: 34px;
      line-height: 20px;
      color: base.$tertiary6;
      width: 88%;
      border-radius: 10px;
      background: none;

      &:focus-visible {
        outline: none;
      }
    }

    .file-upload-search-list {
      position: absolute;
      top: 36px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      z-index: 99;

      .file-upload-search-list-contact {
        @include base.OmnesMedium;
        padding: 12px 14px;
        cursor: pointer;
        font-size: 16px;
        line-height: 22px;
        color: base.$tertiary6;
      }
    }
  }

  .file-upload-cost-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid base.$tertiary5;

    div:first-child {
      padding-top: 8px;
    }
  }
  .file-upload-cost-token {
    width: fit-content;
    border: 1px solid base.$tertiary4;
    box-sizing: border-box;
    border-radius: 16px;
    align-items: center;
    padding-right: 16px;
    margin-bottom: 25px;
    background: base.$primary;
    color: white;
    font-size: 20px;
    line-height: 34px;
    @include base.OmnesSemiBold;

    img {
      margin-left: 12px;
      margin-right: 16px;
    }
  }

  .file-upload-cost-detail {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    line-height: 20px;
    color: base.$tertiary6;
    padding-bottom: 26px;
    border-bottom: 1px solid base.$secondary2;
  }

  .file-upload-cost {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include base.OmnesSemiBold;
    font-size: 15px;
    line-height: 22px;
    color: base.$tertiary6;
    padding-bottom: 26px;
  }

  .file-upload-share-with {
    margin-top: 16px;

    .file-upload-share-with-empty {
      @include base.OmnesLight;
      font-size: 15px;
      line-height: 147.12%;
      color: base.$primary;
    }
  }
  .file-upload-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.file-upload-share-with-user {
  display: flex;
  flex-direction: row;
  flex-flow: row;
  background: base.$secondary2-alpha50;
  border: 0.5px solid base.$primary;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 5px 7px;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  .file-upload-share-with-user-address {
    width: 25px;
    height: 25px;
    background: base.$secondary8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 14px;
    }
  }

  .file-upload-share-with-user-name {
    padding-left: 7px;
    font-size: 16px;
    line-height: 22px;
    color: base.$tertiary6;
    width: fit-content;
    @include base.OmnesMedium;
  }

  .file-upload-share-with-user-trash {
    margin-left: auto;
    img {
      width: 22px;
      cursor: pointer;
    }
  }
}
