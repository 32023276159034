@use "base";
// This file is for styles that apply *for the whole site*. For example, the primary colour for react,
// and the default styling for a form. CSS that is only for a particular component should go in the
// corresponding .scss file, e.g. MyComponent.scss

// Colours for bootstrap
$primary: base.$primary;
$secondary: base.$secondary1;

// imported here so that the colours above override the bootstrap defaults
@import "bootstrap";

.offcanvas {
  background: base.$secondary8;
  box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
  .offcanvas-header {
    border-bottom: none;
    padding: 1.5rem;

    .offcanvas-title {
      display: flex;
      flex-direction: column;

      @include base.OmnesSemiBold;

      font-size: 20px;
      line-height: 22px;
      color: base.$tertiary6;
    }
  }
  .offcanvas-body {
    @include base.OmnesLight;

    font-size: 15px;
    line-height: 147.12%;
    color: base.$tertiary6;
    margin-bottom: 10px;
    padding: 1em 1.5rem;
  }
}

form {
  .form-control {
    box-sizing: border-box;

    background: base.$secondary8;

    border: 1px solid base.$primary;
    border-radius: 16px;
  }
  .form-label {
    @include base.OmnesMedium;

    font-size: 16px;

    color: base.$tertiary6;
  }
}

.btn {
  border-radius: 16px;
  @include base.OmnesSemiBold;

  color: base.$secondary8;
}
