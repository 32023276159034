@use "../../base";

.app-header {
  height: 110px;
  display: flex;
  align-items: center;
  padding-left: 35px;

  .app-header-search {
    display: flex;
    width: 55%;
    background: base.$tertiary5-alpha25;
    border: 1px solid base.$tertiary4;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 5px 0;

    input {
      @include base.OmnesMedium;
      width: 95%;
      font-size: 17px;
      line-height: 20px;
      border: 0;
      background: none;
      color: base.$tertiary6;

      &:focus {
        outline: none;
      }
    }
  }

  .app-header-wallet {
    width: 45%;
    padding-right: 24px;
    display: flex;
    justify-content: flex-end;

    .app-header-wallet-connect {
      display: flex;
      cursor: pointer;
      justify-content: center;
      text-align: end;
      max-height: 70%;
      margin-right: 8px;
      padding: 12px;
    }
  }
}
