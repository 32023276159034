@use "../../base";

.page-header {
  display: flex;

  .page-header-info {
    width: 70%;

    .page-header-info-heading {
      @include base.OmnesSemiBold;
      font-size: 28px;
      line-height: 22px;
      color: base.$secondary7;
    }

    .page-header-info-details {
      @include base.OmnesMedium;
      font-size: 16px;
      line-height: 22px;
      color: base.$tertiary6;
      padding-top: 10px;
    }
  }

  .page-header-action {
    width: 30%;
    text-align: end;
  }
}
