@use "../../base";

.button {
  background-color: base.$primary;
  border: none;
  padding: 12px 15px;
  border-radius: 16px;

  &:hover {
    background-color: base.$secondary5;
  }

  span {
    @include base.OmnesSemiBold;
    font-size: 16px;
    line-height: 22px;
    color: base.$secondary8;
    font-feature-settings: "calt" off;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.65;
  }

  &--disabled-hover {
    pointer-events: none;
    cursor: not-allowed !important;
  }
}
