@use "../../base";

.contacts {
  width: 100%;
  padding-top: 21px;
  padding-left: 35px;
  padding-right: 28px;

  .contacts-header {
    padding-bottom: 45px;
  }
}
