// Fonts are imported in index.html from <link rel="stylesheet" href="https://use.typekit.net/nld0vfv.css">
// Fonts from Karen's file "Omnes typeface styles.rtf"
@mixin OmnesRegular {
  font-family: omnes-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin OmnesItalic {
  font-family: omnes-pro, sans-serif;
  font-weight: 500;
  font-style: italic;
}
@mixin OmnesBold {
  font-family: omnes-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
}
@mixin OmnesBoldItalic {
  font-family: omnes-pro, sans-serif;
  font-weight: 800;
  font-style: italic;
}
@mixin OmnesHairline {
  font-family: omnes-pro, sans-serif;
  font-weight: 100;
  font-style: normal;
}
@mixin OmnesHairlineItalic {
  font-family: omnes-pro, sans-serif;
  font-weight: 100;
  font-style: italic;
}
@mixin OmnesLight {
  font-family: omnes-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin OmnesLightItalic {
  font-family: omnes-pro, sans-serif;
  font-weight: 400;
  font-style: italic;
}
@mixin OmnesExtraLight {
  font-family: omnes-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin OmnesExtraLightItalic {
  font-family: omnes-pro, sans-serif;
  font-weight: 300;
  font-style: italic;
}
@mixin OmnesMedium {
  font-family: omnes-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}
@mixin OmnesMediumItalic {
  font-family: omnes-pro, sans-serif;
  font-weight: 600;
  font-style: italic;
}
@mixin OmnesSemiBold {
  font-family: omnes-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin OmnesSemiBoldItalic {
  font-family: omnes-pro, sans-serif;
  font-weight: 700;
  font-style: italic;
}
@mixin OmnesBlack {
  font-family: omnes-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
}
@mixin OmnesBlackItalic {
  font-family: omnes-pro, sans-serif;
  font-weight: 900;
  font-style: italic;
}
@mixin OmnesThin {
  font-family: omnes-thin, sans-serif;
  font-weight: 200;
  font-style: normal;
}
@mixin OmnesThinItalic {
  font-family: omnes-thin, sans-serif;
  font-weight: 200;
  font-style: italic;
}
@mixin OmnesNarrowHairlineItalic {
  font-family: omnes-narrow, sans-serif;
  font-weight: 100;
  font-style: italic;
}
@mixin OmnesNarrowHairline {
  font-family: omnes-narrow, sans-serif;
  font-weight: 100;
  font-style: normal;
}
@mixin OmnesNarrowExtraLight {
  font-family: omnes-narrow, sans-serif;
  font-weight: 200;
  font-style: normal;
}
@mixin OmnesNarrowExtraLightItalic {
  font-family: omnes-narrow, sans-serif;
  font-weight: 200;
  font-style: italic;
}
@mixin OmnesNarrowLightItalic {
  font-family: omnes-narrow, sans-serif;
  font-weight: 300;
  font-style: italic;
}
@mixin OmnesNarrowLight {
  font-family: omnes-narrow, sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin OmnesNarrowRegular {
  font-family: omnes-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin OmnesNarrowItalic {
  font-family: omnes-narrow, sans-serif;
  font-weight: 400;
  font-style: italic;
}
@mixin OmnesNarrowMediumItalic {
  font-family: omnes-narrow, sans-serif;
  font-weight: 500;
  font-style: italic;
}
@mixin OmnesNarrowMedium {
  font-family: omnes-narrow, sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin OmnesNarrowSemiBold {
  font-family: omnes-narrow, sans-serif;
  font-weight: 600;
  font-style: normal;
}
@mixin OmnesNarrowSemiBoldItalic {
  font-family: omnes-narrow, sans-serif;
  font-weight: 600;
  font-style: italic;
}
@mixin OmnesNarrowBold {
  font-family: omnes-narrow, sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin OmnesNarrowBoldItalic {
  font-family: omnes-narrow, sans-serif;
  font-weight: 700;
  font-style: italic;
}
@mixin OmnesNarrowBlack {
  font-family: omnes-narrow, sans-serif;
  font-weight: 900;
  font-style: normal;
}
@mixin OmnesNarrowBlackItalic {
  font-family: omnes-narrow, sans-serif;
  font-weight: 900;
  font-style: italic;
}
@mixin OmnesNarrowThin {
  font-family: omnes-narrow-thin, sans-serif;
  font-weight: 200;
  font-style: normal;
}
@mixin OmnesNarrowThinItalic {
  font-family: omnes-narrow-thin, sans-serif;
  font-weight: 200;
  font-style: italic;
}
@mixin OmnesCondHairlineItalic {
  font-family: omnes-cond, sans-serif;
  font-weight: 100;
  font-style: italic;
}
@mixin OmnesCondHairline {
  font-family: omnes-cond, sans-serif;
  font-weight: 100;
  font-style: normal;
}
@mixin OmnesCondExtraLight {
  font-family: omnes-cond, sans-serif;
  font-weight: 200;
  font-style: normal;
}
@mixin OmnesCondExtraLightItalic {
  font-family: omnes-cond, sans-serif;
  font-weight: 200;
  font-style: italic;
}
@mixin OmnesCondLightItalic {
  font-family: omnes-cond, sans-serif;
  font-weight: 300;
  font-style: italic;
}
@mixin OmnesCondLight {
  font-family: omnes-cond, sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin OmnesCondItalic {
  font-family: omnes-cond, sans-serif;
  font-weight: 400;
  font-style: italic;
}
@mixin OmnesCondRegular {
  font-family: omnes-cond, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin OmnesCondMedium {
  font-family: omnes-cond, sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin OmnesCondMediumItalic {
  font-family: omnes-cond, sans-serif;
  font-weight: 500;
  font-style: italic;
}
@mixin OmnesCondSemiBold {
  font-family: omnes-cond, sans-serif;
  font-weight: 600;
  font-style: normal;
}
@mixin OmnesCondSemiBoldItalic {
  font-family: omnes-cond, sans-serif;
  font-weight: 600;
  font-style: italic;
}
@mixin OmnesCondBold {
  font-family: omnes-cond, sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin OmnesCondBoldItalic {
  font-family: omnes-cond, sans-serif;
  font-weight: 700;
  font-style: italic;
}
@mixin OmnesCondBlack {
  font-family: omnes-cond, sans-serif;
  font-weight: 900;
  font-style: normal;
}
@mixin OmnesCondBlackItalic {
  font-family: omnes-cond, sans-serif;
  font-weight: 900;
  font-style: italic;
}
@mixin OmnesCondThinItalic {
  font-family: omnes-cond-thin, sans-serif;
  font-weight: 200;
  font-style: italic;
}
@mixin OmnesCondThin {
  font-family: omnes-cond-thin, sans-serif;
  font-weight: 200;
  font-style: normal;
}
@mixin OmnesSemiCondHairline {
  font-family: omnes-semicond, sans-serif;
  font-weight: 100;
  font-style: normal;
}
@mixin OmnesSemiCondHairlineItalic {
  font-family: omnes-semicond, sans-serif;
  font-weight: 100;
  font-style: italic;
}
@mixin OmnesSemiCondExtraLightItalic {
  font-family: omnes-semicond, sans-serif;
  font-weight: 200;
  font-style: italic;
}
@mixin OmnesSemiCondExtraLight {
  font-family: omnes-semicond, sans-serif;
  font-weight: 200;
  font-style: normal;
}
@mixin OmnesSemiCondLightItalic {
  font-family: omnes-semicond, sans-serif;
  font-weight: 300;
  font-style: italic;
}
@mixin OmnesSemiCondLight {
  font-family: omnes-semicond, sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin OmnesSemiCondRegular {
  font-family: omnes-semicond, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin OmnesSemiCondItalic {
  font-family: omnes-semicond, sans-serif;
  font-weight: 400;
  font-style: italic;
}
@mixin OmnesSemiCondMediumItalic {
  font-family: omnes-semicond, sans-serif;
  font-weight: 500;
  font-style: italic;
}
@mixin OmnesSemiCondMedium {
  font-family: omnes-semicond, sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin OmnesSemiCondSemiBoldItalic {
  font-family: omnes-semicond, sans-serif;
  font-weight: 600;
  font-style: italic;
}
@mixin OmnesSemiCondSemiBold {
  font-family: omnes-semicond, sans-serif;
  font-weight: 600;
  font-style: normal;
}
@mixin OmnesSemiCondBoldItalic {
  font-family: omnes-semicond, sans-serif;
  font-weight: 700;
  font-style: italic;
}
@mixin OmnesSemiCondBold {
  font-family: omnes-semicond, sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin OmnesSemiCondBlackItalic {
  font-family: omnes-semicond, sans-serif;
  font-weight: 900;
  font-style: italic;
}
@mixin OmnesSemiCondBlack {
  font-family: omnes-semicond, sans-serif;
  font-weight: 900;
  font-style: normal;
}
@mixin OmnesSemiCondThin {
  font-family: omnes-semicond-thin, sans-serif;
  font-weight: 200;
  font-style: normal;
}
@mixin OmnesSemiCondThinItalic {
  font-family: omnes-semicond-thin, sans-serif;
  font-weight: 200;
  font-style: italic;
}

// Fonts from Karen's file "poppins typeface styles.rtf"
@mixin PoppinsThinItalic {
  font-family: poppins, sans-serif;
  font-weight: 100;
  font-style: italic;
}
@mixin PoppinsThin {
  font-family: poppins, sans-serif;
  font-weight: 100;
  font-style: normal;
}
@mixin PoppinsExtraLight {
  font-family: poppins, sans-serif;
  font-weight: 200;
  font-style: normal;
}
@mixin PoppinsExtraLightItalic {
  font-family: poppins, sans-serif;
  font-weight: 200;
  font-style: italic;
}
@mixin PoppinsLight {
  font-family: poppins, sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin PoppinsLightItalic {
  font-family: poppins, sans-serif;
  font-weight: 300;
  font-style: italic;
}
@mixin PoppinsItalic {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-style: italic;
}
@mixin PoppinsRegular {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin PoppinsMediumItalic {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-style: italic;
}
@mixin PoppinsMedium {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin PoppinsSemiBoldItalic {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-style: italic;
}
@mixin PoppinsSemiBold {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-style: normal;
}
@mixin PoppinsBoldItalic {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-style: italic;
}
@mixin PoppinsBold {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin PoppinsExtraBoldItalic {
  font-family: poppins, sans-serif;
  font-weight: 800;
  font-style: italic;
}
@mixin PoppinsExtraBold {
  font-family: poppins, sans-serif;
  font-weight: 800;
  font-style: normal;
}
@mixin PoppinsBlackItalic {
  font-family: poppins, sans-serif;
  font-weight: 900;
  font-style: italic;
}
@mixin PoppinsBlack {
  font-family: poppins, sans-serif;
  font-weight: 900;
  font-style: normal;
}
// Colours from Karen's file "DabbaFlow colour styles.rtf"
$primary: #f48e06;
$secondary1: #f4bb76;
$secondary2: #fcecdb;
$secondary3: #f4d30b;
$secondary4: #f4c3da;
$secondary5: #e72479;
$secondary6: #afdefa;
$secondary7: #070707;
$secondary8: #ffffff;
$tertiary1: #1c1d3c;
$tertiary2: #9a9af7;
$tertiary3: #8e2bc1;
$tertiary4: #b0b1b5;
$tertiary5: #dddddd;
$tertiary6: #667080;
$tertiary7: #052366;
$tertiary8: #0e51c6;
$tertiary9: #334c9c;
$tertiary10: #c5dcfd;
$tertiary11: #41aaf5;
$tertiary12: #6cc3c1;
$tertiary13: #4d823c;
$tertiary14: #b8d361;
$tertiary15: #f90338;
$tertiary16: #7c4d09;
$tertiary17: #c27248;
$tertiary18: #e8c7aa;
$tertiary19: #ffd3cb;

// colours with opacity
$secondary1-alpha40: #f4bb7666;
$secondary2-alpha15: #fcecdb26;
$secondary2-alpha50: #fcecdb80;
$tertiary5-alpha25: #dddddd40;

$color-white: #ffffff;
