@use "../../base";

.detailsButton {
  width: 25px;
  height: 25px;
  background-image: radial-gradient(
    circle,
    base.$tertiary6 3px,
    transparent 3px
  );
  background-size: 100% 33.33%;
  background-position: center center;
}

.outlinedTextBox {
  position: relative;
  width: 100%;
  border: 1px solid base.$primary;
  box-sizing: border-box;
  border-radius: 16px;
  @include base.OmnesSemiBold;
  font-size: 20px;
  line-height: 22px;
  color: base.$tertiary6;
  padding: 12px;
  padding-left: 16px;
}

.outlinedTextBoxUnderText {
  @include base.OmnesMedium;
  font-size: 14px;
  line-height: 14px;
  color: base.$tertiary6;
  padding: 4px;
  padding-top: 8px;
}

.tabs {
  border: none !important;
  padding-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  .nav-link {
    border: 0 !important;
    border-bottom: 1px solid base.$tertiary6 !important;
    color: base.$tertiary6 !important;
    width: 170px;
    background-color: red;
  }

  .active {
    border: 0 !important;
    border-bottom: 4px solid base.$primary !important;
    @include base.OmnesSemiBold;
    width: 170px;
  }
}

.detailsTabTitle {
  img {
    width: 24px;
    height: 24px;
  }
}

.dataDetailsSharedWith {
  margin-top: 48px;
  width: 100%;
  border: 1px solid base.$primary;
  box-sizing: border-box;
  border-radius: 16px;
  @include base.OmnesSemiBold;
  font-size: 20px;
  line-height: 22px;
  color: base.$tertiary6;
  padding-left: 16px;
  background-color: base.$secondary2;

  div {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  span {
    @include base.OmnesSemiBold;
    padding-left: 8px;
  }

  .keyIcon {
    padding-left: 16px;
  }
}

.dataDetailsAuditBox {
  margin-top: 48px;
  width: 100%;
  border: 1px solid base.$primary;
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 18px;
  line-height: 20px;
  color: base.$tertiary6;
  padding-left: 4px;
  padding-right: 2px;
  background-color: base.$secondary2;
  clear: both;
  padding-top: 16px;

  div {
    clear: both;
    display: table;
  }

  img {
    width: 7%;
    padding-right: 4px;
    padding-top: 4px;
    float: left;
  }

  p {
    width: 93%;
    float: left;
  }
}

.contactSearch {
  position: relative;
  width: 100%;
  background: base.$tertiary5;
  border: 1px solid base.$tertiary4;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 25px;
  background: base.$tertiary5-alpha25;

  div {
    width: 100%;
    background: none;
  }

  input {
    border: none;
    @include base.OmnesRegular;
    font-size: 16px;
    height: 34px;
    line-height: 20px;
    color: base.$tertiary6;
    width: 88%;
    border-radius: 10px;
    background: none;

    &:focus-visible {
      outline: none;
    }
  }
}
