@use "../../base";

td.shareRowIcon {
  max-width: 20px;

  svg {
    margin: auto;
    width: 50px;
    opacity: 70%;

    &:hover {
      cursor: pointer;
      opacity: 100%;
    }
  }
}

.selectDelete {
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  @include base.OmnesMedium;
  color: base.$tertiary6;

  input {
    margin-left: 15px;
  }

  .selectDeleteLabel {
    margin-left: 10px;
  }

  .selectDeleteTrash {
    margin-left: 50px;
  }

  img {
    margin-left: 10px;
  }

  img:hover {
    cursor: pointer;
    opacity: 70%;
  }

  #fileCount {
    margin-left: auto;
  }
}
