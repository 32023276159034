@use "../../base";

.nav-bar {
  background: base.$secondary8;
  height: 100vh;
  box-shadow: 0 5px 10px base.$tertiary5;

  .nav-bar-container {
    margin-left: 16px;
    margin-right: 12px;

    .nav-bar-container-heading {
      display: flex;
      padding: 24px 0 15px 0;
      width: 100%;

      &.nav-bar-container-heading--close {
        padding-left: 14px;
        padding-right: 0;
      }

      .nav-bar-container-heading-title {
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;

        #appHeaderIcon {
          width: 24.36px;
          height: 26px;
        }

        #appHeaderTitle {
          padding-left: 10px;
          flex: 2 1 auto;
          display: flex;
          flex-direction: column;
          text-align: left;
          #appHeaderTitleMain {
            @include base.OmnesSemiBold;
            font-size: 22px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #070707;
          }

          #appHeaderTitleSub {
            @include base.PoppinsRegular;
            font-size: 10.9px;
            line-height: 16px;
            color: base.$secondary7;
          }
        }
      }

      .nav-bar-container-heading-icon {
        display: flex;
        justify-content: flex-end;
        padding-left: 5px;
        img {
          cursor: pointer;
          width: 25px;
        }
      }
    }

    .nav-bar-container-navigation {
      @include base.OmnesMedium;

      .nav-bar-container-navigation--active {
        @include base.OmnesSemiBold;
        background: base.$secondary2-alpha50;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        border-left-width: 7px;
        border-left-style: solid;
        border-left-color: base.$primary;
      }

      a {
        width: 100%;
        height: 52px;
        align-items: center;
        display: flex;
        color: base.$tertiary6;
        text-decoration: none;
        margin-bottom: 13px;

        &:hover {
          color: inherit;
        }
      }
      span {
        padding-left: 25px;
      }
      img {
        padding-left: 20px;
      }

      &-bottom {
        position: absolute;
        bottom: 0;
      }
    }

    .nav-bar-container-navigation-close {
      a {
        justify-content: center;

        img {
          padding-left: 0;
        }
      }
    }
  }
}
